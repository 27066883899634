
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import { trackClickEvent } from '@utils/analytics';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import Button from '@/src/components/Button';
import Image from '../../../../components/Image';
import spaceshipIcon from './spaceship-icon.svg';
import s from './ShortDescription.module.scss';
const propTypes = {
    eyebrow: PropTypes.string,
    shortDescription: PropTypes.string,
    background: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    ctaIcon: PropTypes.string,
    reducedHeight: PropTypes.bool
};
const spaceshipIconSet = [
    {
        src: spaceshipIcon
    },
];
const ShortDescription = ({ eyebrow = '', shortDescription = '', background = '', ctaLabel = '', ctaUrl = '', ctaIcon = '', reducedHeight = false, }) => {
    const { componentRef, verticalIndex } = useVerticalIndex();
    return (<div data-testid="shortDescriptionRoot" className={[s.root, reducedHeight ? s.reducedHeight : ''].join(' ')} ref={componentRef}>
      <div className={s.container} style={{ background }}>
        <div className={s.inner}>
          <div className={s.eyebrow}>{eyebrow}</div>
          <h2 className={s.description}>{shortDescription}</h2>
          {ctaLabel && ctaUrl && (<>
              <Button type="secondary" label={ctaLabel} className={s.btn} contentLeft={ctaIcon} data-testid="cta-button" onClick={() => {
                trackClickEvent({
                    destination_url: ctaUrl,
                    label: ctaLabel,
                    location: 'Collection',
                    vertical_index: verticalIndex,
                    horizontal_index: 0
                });
                window.location = ctaUrl;
            }}/>
            </>)}
          {(!ctaLabel || !ctaUrl) && (<div className={s.spaceshipIcon}>
              <Image imageSet={spaceshipIconSet} contain/>
            </div>)}
        </div>
      </div>
    </div>);
};
ShortDescription.propTypes = propTypes;
export default ShortDescription;

    async function __Next_Translate__getStaticProps__195ca0cad02__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/ShortDescription/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ca0cad02__ as getStaticProps }
  